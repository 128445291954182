<template>
  <main id="support-page-card">
    <div class="left-side">
      <slot name="icon" />
    </div>
    <div class="right-side">
      <header>
        <h2>{{ title }}</h2>
        <h6>{{ subtitle }}</h6>
      </header>
      <div class="copy-to-clipboard">
        <span>
          {{ textToClipboard }}
        </span>
        <button
          @click="copyToClipboard"
          v-b-tooltip.hover="{ customClass: 'support-card-tooltip' }"
          :title="copied ? tooltip.copied : tooltip.copy"
        >
          <CopyIcon v-if="!copied" />
          <CheckIcon v-else />
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import CopyIcon from "@/assets/images/icons/paste-icon-orange.svg";
import CheckIcon from "@/assets/images/icons/check-circle-orange.svg";
import ToastComponent from "@core/components/toastification/ToastificationContent.vue";
import { VBTooltip } from "bootstrap-vue";

export default {
  components: {
    CheckIcon,
    CopyIcon,
  },
  props: ["title", "subtitle", "textToClipboard", "tooltip", "toast", "type"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    formatPhoneNumber(value) {
      return value.replace(/\D/g, "");
    },
    async handleClipboard() {
      let clipboardValue =
        this.type === "phone"
          ? this.formatPhoneNumber(this.textToClipboard)
          : this.textToClipboard;

      const isNavigatorSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      if (isNavigatorSafari) {
        this.handleSafariClipboard(clipboardValue);
      } else {
        try {
          await navigator.clipboard.writeText(clipboardValue);
          this.copied = true;
        } catch (err) {
          // error handling
        }
      }
    },
    handleSafariClipboard(text) {
      const element = document.createElement("input");
      element.value = text;
      element.setAttribute("readonly", true); // Evita um bug visual no Safari/iPhone
      document.body.appendChild(element);
      element.select();
      document.execCommand("copy");
      document.body.removeChild(element);
    },
    async copyToClipboard() {
      if (!this.copied) {
        await this.handleClipboard();
        if (window.screen.width < 768) this.showToast();

        setTimeout(() => {
          this.copied = false;
        }, 2000);
      }
    },
    showToast() {
      if (window.screen.width < 768) {
        const message = this.toast.title;
        this.$toast({
          component: ToastComponent,
          props: {
            title: this.$t("SupportPage.Success"),
            icon: "SmileIcon",
            text: message,
            variant: "success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
#support-page-card {
  padding: 0;
  margin: 0;
  min-height: 130px;
  display: flex;
  flex-direction: row;
}

#support-page-card {
  background: #ffffff;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
    0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  .left-side,
  .right-side {
    padding: 16px;
    vertical-align: baseline;
  }
  .right-side {
    border-left: 1px solid #cfc4be;
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: space-between;
    min-width: 265px;
  }
  .left-side {
    min-width: 74px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: #4c4541;
    }
  }

  h2,
  h6 {
    font-family: "Raleway";
    font-style: normal;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4c4541;
  }

  h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #998f8a;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #974900;
    display: flex;
    align-items: flex-end;

    margin-bottom: 4px;
  }

  .copy-to-clipboard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      outline: 0;
      border: 0;
      padding: 4px 8px;
      background: #974900;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      touch-action: manipulation;

      &:hover {
        background: #9f5715;
      }

      svg {
        margin: -2px 0 0 -1px;
      }
      path {
        fill: white;
      }
    }
  }
}

.support-card-tooltip {
  margin-bottom: 8px;
  .arrow::before {
    border-top-color: #1a1c19;
  }

  .tooltip-inner {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    padding: 8px;
    background-color: #1a1c19;
    border-radius: 6px;
    border: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1215px) {
  #support-page-card {
    .right-side {
      width: 238px !important;
      min-width: 0px !important;
      padding-left: 10px !important;
    }
  }
}

@media (max-width: 480px) {
  #support-page-card {
    min-height: 110px;
  }
  .left-side {
    min-width: 74px !important;
  }
  .right-side {
    min-width: 213px !important;
  }
  .left-side,
  .right-side {
    padding: 8px !important;
  }
  h5 {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  h6 {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  span {
    margin-bottom: 5px !important;
  }
  button {
    margin-right: 5px !important;
  }
}
</style>
